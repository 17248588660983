import { createFeatureSelector, createSelector } from '@ngrx/store';

import { AccountState, AccountTokens } from '../states-models';

export const selectAccountState = createFeatureSelector<AccountState>(
  'account'
);


export const selectUsername = createSelector(
  selectAccountState,
  (state: AccountState) => state.username
);

export const selectTokens = createSelector(
  selectAccountState,
  (state: AccountState) => state?.tokens
);

export const selectAccessToken = createSelector(
  selectTokens,
  (state: AccountTokens) => state.access_token
);

export const selectJwt = createSelector(
  selectTokens,
  (state: AccountTokens) => state.jwt
);

export const selectSipCredentials = createSelector(
  selectAccountState,
  (state: AccountState) => state.sipCredentials
);

export const selectPbxId = createSelector(
  selectAccountState,
  (state: AccountState) => state.session?.pbx_id
);

export const selectCompanyId = createSelector(
  selectAccountState,
  (state: AccountState) => state.companyId
);

export const selectSubscription = createSelector(
  selectAccountState,
  (state: AccountState) => state?.subscription
);

export const selectSession = createSelector(
  selectAccountState,
  (state: AccountState) => state?.session
);

export const selectIntegrations = createSelector(
  selectAccountState,
  (state: AccountState) => state?.integrations
);

export const selectIntegrationsEnabled = createSelector(
  selectAccountState,
  (state: AccountState) => Boolean(state.subscription.addons.find(a => a.id.includes('crm_integrations')))
);
