import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin, Subscription } from 'rxjs';
import { AccountService } from '../../../account/services';
import { AccountFacade } from '../../../account/store/facade';
import { DidsWithLabel, SessionDTO, SipCredentials } from '../../../account/store/states-models';
import { CallHistoryRecord, CallHistoryRecordType, DatabaseService, SipService, PresenceModel, AddressbookRecord, ADDRESSBOOK_TYPES, AddressbookService, APIAddressRecord, PaginatedAPIAddressbookContacts, APIAddressbookFilter, APIAddressbookSearchProperties, PRESENCE_STATUS } from '../../../core/services';
import { NotificationService } from '../../../core/services/notifications';
import { StartCallOutputType } from './start-call-modal.model';
import { PresenceService } from '../../../core/services/sip/presence.service';
import { debounceTime, map, take } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { RecordFullnamePipe } from '../../pipes';
import { UtilsService } from '../../services';
import { UserFacade } from '../../stores/user/facade/user.facade';
import { User } from '../../models/user.model';
import { Device, Did, PbxUser } from '../../../account/models';

export interface StartCallContactModel {
  fullname: string;
  number: string;
  originalContact: any;
}

const SEARCH_PROPERTIES: APIAddressbookSearchProperties[] = [
  APIAddressbookSearchProperties.FIRST_NAME,
  APIAddressbookSearchProperties.LAST_NAME,
  APIAddressbookSearchProperties.PHONE_NUMBER
]

@Component({
  selector: 'start-call-modal',
  templateUrl: './start-call-modal.component.html',
  styleUrls: [
    '../../styles/contact-list/contact-list.scss',
    './start-call-modal.component.scss'
  ]
})
export class StartCallModalComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];

  public ADDRESSBOOK_TYPES = ADDRESSBOOK_TYPES;
  public PRESENCE_STATUS = PRESENCE_STATUS;

  public isNewCall: boolean = true;
  public startingValue: string;
  private numberRegex = /(^\+)?\d+$/;

  public currentOutboundNumber: DidsWithLabel = {
    label: '',
    number: ''
  };
  public outboundDidList: DidsWithLabel[];

  public currentDigitedValue: string = '';
  public isValidNumber: boolean = false;
  public showDialer: boolean = false;
  public searchResults: StartCallContactModel[] = [];
  public recents: CallHistoryRecord[] = [];

  private contacts: any[] = [];

  public outboundNumberLoading: boolean = false;
  public initLoading: boolean = false;
  public searchLoading: boolean = false;
  public searchInput: FormControl = new FormControl('');

  private presenceList: PresenceModel[];

  private session: SessionDTO;
  private filters: APIAddressbookFilter;

  public usersToSpy: User[] = [];

  constructor(
    private modalRef: NgbActiveModal,
    private accountFacade: AccountFacade,
    private accountService: AccountService,
    private dbService: DatabaseService,
    private notificationService: NotificationService,
    private presenceService: PresenceService,
    private addressbookService: AddressbookService,
    private fullNamePipe: RecordFullnamePipe,
    private utilsService: UtilsService,
    private userFacade: UserFacade
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(
      this.accountFacade.session$.subscribe((session: SessionDTO) => {
        this.outboundNumberLoading = true;
        const getUser = this.accountService.getUser(session.pbx_id, session.pbx_user_id);
        const getDevice = this.accountService.getDevices(session.pbx_id, session.pbx_user_id).pipe(map(
          (devices: Device[]) => {
            return devices.find((device: Device) => device.id === session.device_id)
          }
        ));
        forkJoin({
          user: getUser,
          device: getDevice
        }).subscribe((response: {
          user: PbxUser,
          device: Device
        }) => {
          this.outboundDidList = response.user.outbound_config.available_dids.map((did: Did) => {
            return {
              label: did.label,
              number: did.number,
              id: did.id
            }
          });
          this.currentOutboundNumber = {
            label: response.device.outbound_config.outbound_did.label,
            number: response.device.outbound_config.outbound_did.number,
            id: response.device.outbound_config.outbound_did.id
          }
          this.outboundNumberLoading = false;
        }, () => {
          this.notificationService.error('start-call-modal.errors.get-outbound-failed');
          this.outboundNumberLoading = false;
        });
        this.session = session;
        this.userFacade.getAllUsersWhoCanBeSpied(session.pbx_user_id).pipe(take(1)).subscribe(r => {
          this.usersToSpy = r;
        });
      })
    );

    this.presenceService.presenceSubscriptions$.pipe(take(1)).subscribe(p => this.presenceList = p);
    this.subscriptions.push(
      this.presenceService.presenceSubscription$.subscribe((presence: PresenceModel) => {
        const contact = this.contacts.find(c => c.presenceId && c.presenceId === presence.entity);
        if(contact) contact.status = presence.status;
        const result = this.searchResults.find(c => c?.originalContact?.presenceId && c?.originalContact?.presenceId === presence.entity);
        if(result) result.originalContact.status = presence.status;
        let recent = this.recents.find(r => r.displayName.number === presence.entity);
        if(recent) recent.status = presence.status;
      })
    );

    this.initLoading = true;
    forkJoin(
      {
        locals: this.dbService.getAllAddressbookRecords(),
        recents: this.dbService.getSomeCallHistoryRecordsByDateWithTypes(5, [CallHistoryRecordType.OUTGOING_ANSWERED, CallHistoryRecordType.OUTGOING_MISSED], undefined, false, true)
      }
    ).subscribe((res: {
      locals: AddressbookRecord[],
      recents: CallHistoryRecord[]
    }) => {
      this.contacts = res.locals;
      this.recents = res.recents;
      this.subscribeInternalUsersToPresence(this.contacts, this.recents);
      if(this.currentDigitedValue) this.onInputChange();
      this.initLoading = false;
    }, (error) => {
      this.initLoading = false;
    });

    this.searchInput.valueChanges.pipe(debounceTime(200)).subscribe((value: string) => {
      this.currentDigitedValue = value;
      this.onInputChange();
    })
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  public close(): void {
    this.modalRef.close();
  }

  public onOpenOutboundList(outbound: NgbDropdown): void {
    outbound.isOpen() ? outbound.close() : outbound.open();
  }

  public onSelectOutboundDid(did: DidsWithLabel): void {
    this.outboundNumberLoading = true;
    this.accountService.updateOutboundDid(
      this.session.pbx_id, 
      this.session.pbx_user_id, 
      this.session.device_id, 
      did.number
    ).subscribe((response: Device) => {
      this.currentOutboundNumber = did;
      this.accountFacade.setOutboundNumber(did.number);
      this.outboundNumberLoading = false;
    },() => {
      this.notificationService.error('start-call-modal.errors.update-outbound-failed');
      this.outboundNumberLoading = false;
    })
  }

  public onClearInput(): void {
    this.searchInput.patchValue('');
  }

  public onInputChange(): void {
    this.isValidNumber = this.utilsService.isValidPhoneNumber(this.utilsService.cleanNumber(this.currentDigitedValue));
    this.filters = {
      input: this.currentDigitedValue.toLowerCase(),
      properties: SEARCH_PROPERTIES
    }
    this.addressbookService.getContacts(this.session.company_id, this.filters).subscribe((externals: PaginatedAPIAddressbookContacts) => {
      let tmpRes = [...this.contacts.filter(this.filterContacts, this), ...externals.content.map(this.addressbookService.fromAPIAddressbookRecord)];
      this.searchResults = tmpRes.map(this.mapContacts, this).slice(0, 5);
    });
  }


  private filterContacts(contact: AddressbookRecord): boolean {
    const searchTerm = this.currentDigitedValue.toLowerCase().trim();
    return `${contact.firstName.toLowerCase()} ${contact.lastName?.toLowerCase()}`.includes(searchTerm) || contact.defaultNumber?.number?.includes(searchTerm);
  }

  private mapContacts(contact: any): StartCallContactModel {
    return {
      fullname: this.fullNamePipe.transform(contact),
      number: contact.defaultNumber?.number,
      originalContact: contact
    }
  }

  public onShowDial(): void {
    this.showDialer = true;
  }

  public onShowContacts(): void {
    this.showDialer = false;
  }

  public onDialerKeyPressed(event: string): void {
    this.currentDigitedValue += event;
    this.onInputChange();
  }

  public callFromRecents(recent: CallHistoryRecord): void {
    this.modalRef.close({
      contact: recent,
      type: StartCallOutputType.EVENT
    })
  }
  public callFromContacts(contact: AddressbookRecord): void {
    let type: StartCallOutputType;
    switch(contact.type) {
      case ADDRESSBOOK_TYPES.EXTERNAL: {
        type = StartCallOutputType.EXTERNAL_VCARD;
      }
      break;
      case ADDRESSBOOK_TYPES.INTERNAL_USER: {
        type = StartCallOutputType.INTERNAL_USER
      }
      break;
      case ADDRESSBOOK_TYPES.INTERNAL_GROUP: {
        type = StartCallOutputType.INTERNAL_GROUP
      }
    }
    this.modalRef.close({
      contact: contact,
      type: type
    })
  }
  public callFromNumber(number: string): void {
    const cleanNumber = this.utilsService.cleanNumber(number);
    if(this.isValidNumber) {
      this.modalRef.close({
        contact: null,
        type: StartCallOutputType.NUMBER,
        number: cleanNumber
      })
    }
  }

  private subscribeInternalUsersToPresence(users: AddressbookRecord[], recents: CallHistoryRecord[]) {
    users.forEach(u => {
      if(u.type === ADDRESSBOOK_TYPES.INTERNAL_USER){
        const idx = this.presenceList.findIndex(value => value.entity === u.presenceId);
        if(idx === -1) {
          this.presenceService.subscribeEntity(u.presenceId || u.defaultNumber.number);
        } else {
          u.status = this.presenceList[idx].status;
        }
      }
    });
    recents.forEach((recent) => {
      if(recent.addressbookType === ADDRESSBOOK_TYPES.INTERNAL_USER){
        const idx = this.presenceList.findIndex(v => v.entity === recent.displayName.number);
        if(idx === -1){
          this.presenceService.subscribeEntity(recent.displayName.number);
        } else {
          recent.status = this.presenceList[idx].status;
        }
      }
    })
  }
}
